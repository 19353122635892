import './App.css'
import { Button } from './components/Button'
import { Col, Container, Row } from 'react-grid-system'
import { Logo } from './components/Logo'
import { Swiper, SwiperSlide } from 'swiper/react'
import infrastructure from './assets/infrastructure.svg'
import commission from './assets/commission.svg'
import automation from './assets/automation.svg'
import gifts from './assets/gifts.svg'
import security from './assets/security.svg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

// import './styles.css'

// import required modules
import { EffectCards, FreeMode, Pagination } from 'swiper'
import { Card } from './components/Card'
function App() {
  return (
    <div className="App">
      <div className="AppContent">
        <header className="Header">{'Octo-Node'}</header>

        <div className="Content">
          <Logo className="Logo" />
          <Container className="ButtonContainer" fluid>
            <Row className="ButtonRow" nogutter justify="around">
              <Col xl={3} md={3} sm={4} xs={10}>
                <Button
                  link="https://www.mintscan.io/ki-chain/validators/kivaloper1sl9z6ts5w8ls6tank008l6qcmyxhstreu2ttma"
                  text={'Stake on Ki'}
                  className={'KiButton'}
                />
              </Col>
              <Col xl={3} md={3} sm={4} xs={10}>
                <Button
                  link={
                    'https://www.mintscan.io/chihuahua/validators/chihuahuavaloper1s6l7nqd96sle9nsrcvzh6rgajrq8xnf2palpqx'
                  }
                  text={'Stake on Chihuahua'}
                  className={'HuaButton'}
                />
              </Col>
            </Row>
          </Container>
          <div className="HostingContainer">
            <div className="HostingDivider">
              <Swiper
                slidesPerView={1}
                breakpoints={{
                  550: {
                    slidesPerView: 2,
                  },
                  840: {
                    slidesPerView: 3,
                  },
                }}
                slidesOffsetBefore={25}
                slidesOffsetAfter={25}
                spaceBetween={30}
                // freeMode={true}
                // pagination
                modules={[EffectCards]}
                className="SwiperCarousel"
              >
                <SwiperSlide unselectable="on">
                  <Card
                    title="State of the art infrastructure"
                    content="Redundant infrastructure with sentries and tmkms to ensure the best validating experience and security"
                    image={security}
                  />
                </SwiperSlide>
                <SwiperSlide unselectable="on">
                  <Card
                    title="Top tier cloud providers"
                    content="Hosted on AWS, Scaleway, Hetzner for maximum uptime and reliability"
                    image={infrastructure}
                  />
                </SwiperSlide>
                <SwiperSlide unselectable="on">
                  <Card
                    title="Lowest commissions"
                    content="Maintaining the lowest commissions to empower the web 3.0 community"
                    image={commission}
                  />
                </SwiperSlide>
                <SwiperSlide unselectable="on">
                  <Card
                    title="Daily drops"
                    content="Receive 50% of our commission daily from staking with us on Chihuahua"
                    image={gifts}
                  />
                </SwiperSlide>
                <SwiperSlide unselectable="on">
                  <Card
                    title="Auto compound"
                    content="Coming soon...It's in the pipes"
                    image={automation}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="Footer">
          <Container className="ButtonContainer" fluid>
            <Row className="ButtonRow" nogutter justify="between">
              <Col md={2} xs={4}>
                <Button
                  link="https://twitter.com/OctoNodeStaking"
                  text={'Twitter'}
                  className={'Twitter'}
                />
              </Col>
              <Col md={2} xs={4}>
                <Button
                  link="mailto:contact@octo-node.xyz"
                  text={'Contact us'}
                  className={'Contact'}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default App
