import React from 'react'

interface CardProps {
  image?: string
  title: string
  content: string
}
export const Card: React.FC<CardProps> = ({ image, title, content }) => {
  return (
    <div className="CardContainer">
      {image ? <img className="CardImage" src={image} /> : null}
      <text className="CardTitle">{title}</text>
      <text className="CardContent">{content}</text>
    </div>
  )
}
