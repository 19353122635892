import classNames from 'classnames'
import React from 'react'
import Link from 'next/link'

interface ButtonProps {
  text: string
  onClick?: () => void
  className?: string
  link?: string
}
export const Button: React.FC<ButtonProps> = ({ text, onClick, className, link }) => {
  if (link) {
    return (
      <Link href={link}>
        <a className={classNames('Button', className)} target={'_blank'}>
          {text}
        </a>
      </Link>
    )
  }
  if (onClick)
    return (
      <button className={classNames('Button', className)} onClick={onClick}>
        {text}
      </button>
    )
  return null
}
